.WindStatsTable {
  font-size: 2vh;
  font-weight: bold;
  text-shadow: 3px 3px 5px black, -1px -1px 1px black;
  justify-content: center;
  z-index: 10;
  position: relative;
  left: 2vh;
  table {
    width: calc(30vh - 2em);
  }
  th {
    font-size: 0.75em;
  }
  td {
    text-align: center;
  }
  td:first-child {
    text-align: left;
  }
}
