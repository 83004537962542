#WindWidget {
  position: relative;
  z-index: 10;
  display: inline-flex;
  border-radius: 10px;
  // background-image: linear-gradient(
  //     hsla(240, 100%, 50%, 0.5),
  //     15%,
  //     rgba(0, 0, 51, 0.5)
  //   ),
  //   linear-gradient(transparent, 98%, hsla(240, 100%, 98%, 1));

  .Compass {
    display: inline-block;
  }
}
