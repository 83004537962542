.Menu {
  font-family: "HelveticaNeue";
  z-index: 100;
  position: fixed;
  top: 0;
  left: -100vw;
  width: 100vw;
  height: 100vh;
  background-color: hsla(240, 50%, 0%, 0.9);
  ul {
    margin: 0;
    padding: 0;
    text-align: center;
    list-style-type: none;
    li {
      &.navigable {
        &:hover {
          cursor: pointer;
          background-color: hsla(240, 50%, 50%, 0.9);
        }
      }
      padding: 0.5em 0 0.5em 0;
      font-size: 2em;
      border-bottom: 1px solid white;
      &.header {
        height: calc(5vh - 1em);
      }
      &.divider {
        cursor: none;
      }
    }
  }
  &.visible {
    left: 0px;
  }
}
.Hamburger {
  z-index: 101;
  position: absolute;
  top: 1vh;
  left: 1vh;
  flex: 1;
  &:hover {
    cursor: pointer;
  }
  .line {
    display: block;
    width: 3vh;
    height: 0.75vh;
    margin-bottom: 0.25vh;
    border-radius: 0.25vh;
    background-color: white;
    &.active {
      &.top {
        transform: translateY(0.5vh) rotate(-45deg);
      }
      &.bottom {
        transform: translateY(-0.5vh) rotate(45deg);
      }
      &.middle {
        display: none;
      }
    }
  }
}
