.Header {
  font-size: 2vh;
  width: 100%;
  height: 5vh;
  display: flex;
  flex-basis: content;
  justify-content: flex-end;
  border-bottom: 1px solid red;
  font-family: "HelveticaNeue", Arial, Helvetica, sans-serif;
  box-shadow: 0px 1px 5px hsl(0, 100%, 50%);
  * {
    margin: 0.5rem 1rem 0 1rem;
  }
}
